import { Action, Dispatch } from 'redux';
import { AppState } from '@redux/types';
import { CurrencyActionType } from '@redux/reducers/currency/enum';

import { ORIGIN, isCN } from '@utils/origin';
import { clientCookies } from '@utils/clientCookies';
import { nextYear } from '@utils/date';

import Cookies from 'universal-cookie';
import { Currencies } from '@shared/enums/currency';
import { Currency } from '@shared/interfaces/currency';
import { _getCurrencyByISO } from '../currencies/selectors';
import { _getProfile } from '../profile/selectors';
import { _isAuthorized } from '../auth/selectors';

export const setCurrency = (currency: Currency) => {
  return {
    type: CurrencyActionType.Set,
    payload: currency,
  };
};

export const saveCurrency =
  (iso: Currencies) =>
  (
    dispatch: Dispatch<Action<CurrencyActionType>>,
    getState: () => AppState
  ) => {
    const state = getState();

    const currency = _getCurrencyByISO(state, iso);

    clientCookies.set('currency_3d4m', currency?.iso, {
      path: '/',
      domain: `.${ORIGIN}`,
      expires: nextYear(),
    });

    dispatch(setCurrency(currency));
  };

export const setInitialCurrency =
  (cookies: Cookies, fromQuery: Currencies) =>
  (dispatch, getState: () => AppState) => {
    const state = getState();
    const isAuthorized = _isAuthorized(state);
    const profile = _getProfile(state);
    const cookieCurrencyISO: Currencies = cookies.get('currency_3d4m');

    if (fromQuery) {
      dispatch(saveCurrency(fromQuery));

      return;
    }

    if (cookieCurrencyISO) {
      dispatch(saveCurrency(cookieCurrencyISO));

      return;
    }

    if (isAuthorized && profile) {
      dispatch(saveCurrency(profile.country.currency.iso));

      return;
    }

    const defaultCurrency = isCN ? Currencies.CNY : Currencies.USD;

    dispatch(saveCurrency(defaultCurrency));

    return;
  };
