import { AppState } from '@redux/types';
import { BasketActionType } from '@redux/reducers/basket/enum';
import { Dispatch } from 'redux';
import { SaveBasket } from '@redux/reducers/basket/types';
import { _getCurrency } from '@redux/reducers/profile/selectors';
import { _getCustomerBillingInfo } from '@redux/reducers/customer/billing/selectors';

import AdobeAnalytics from '@utils/adobeAnalytics';
import AnalyticsManager from '@utils/analyticsManager';
import GoogleAnalytics4 from '@utils/googleAnalytics4';
import { isCN } from '@utils/origin';

import Api from '@api/index';

import { Bundle } from '@shared/enums/bundle';
import { Currencies } from '@shared/enums/currency';
import { Currency } from '@shared/interfaces/currency';

import { Order } from '@shared/interfaces/order';
import { PurchaseStatisticsProduct } from '@shared/interfaces/analytics';
import { SelectedSubscription } from '@shared/interfaces/selected-subscription';
import {
  _generateApiBasket,
  _getBundlesBasket,
} from '@shared/basket/functions';
import { _getAuth, _isAuthorized } from '../auth/selectors';
import { _getBasket, _getObjectsBasket } from './selectors';
import { _getPrices } from '../applications/selectors';
import { _getQueryParams } from '../queryParams/selectors';
import { _sendError } from '@shared/logger/functions';
import { errorHandling } from '../applications/action';

const setBasketAction = (basket: Bundle[]) => ({
  type: BasketActionType.Set,
  payload: basket,
});

const setLoadingBasket = (value: boolean) => ({
  type: BasketActionType.SetLoading,
  payload: value,
});

export const clearBasket = () => dispatch => {
  dispatch(setBasket([]));
};

export const addProductsToBasket =
  (products: SelectedSubscription[]) => async dispatch => {
    const iso = Currencies.USD;
    AnalyticsManager.adobeAnalytics.addBasketStatistics(products, iso);

    const updatedBasketBundles = _getBundlesBasket(products);
    await dispatch(setBasket(updatedBasketBundles));
  };

export const removeProductFromBasket =
  bundle => (dispatch, getState: () => AppState) => {
    const state = getState();
    const basket = _getBasket(state);
    const updatedBasket = basket.filter(item => item !== bundle);
    dispatch(setBasket(updatedBasket));
  };

export const setBasket =
  (bundles: Bundle[]) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const isAuthorized = _isAuthorized(state);

    if (isAuthorized) {
      try {
        dispatch(setLoadingBasket(true));
        await dispatch(saveBasket(bundles));
        dispatch(setBasketAction(bundles));
      } catch (error) {
        dispatch(setLoadingBasket(false));

        return Promise.reject(error);
      }
    } else {
      dispatch(setBasketAction(bundles));
    }
  };

export const saveBasket =
  (bundles?: Bundle[], customCurrency?) =>
  async (dispatch, getState: () => AppState) => {
    const state = getState();
    const queryParams = _getQueryParams(state);
    const currency: Currency = customCurrency
      ? customCurrency
      : _getCurrency(state);
    if (!currency.id) {
      return;
    }

    const objectBasket = _getObjectsBasket(state, bundles);
    const billing = _getCustomerBillingInfo(state);
    const auth = _getAuth(state);
    const tax =
      billing && billing.taxRegistration
        ? { tax_registration: billing.taxRegistration }
        : {};
    const clickId = queryParams.clickId
      ? { click_id: queryParams.clickId }
      : {};

    try {
      const goods = _generateApiBasket(objectBasket);

      const basket: SaveBasket = {
        ...tax,
        ...clickId,
        currency: currency.id,
        goods,
      };

      return await Api.saveBasket(basket, queryParams, auth);
    } catch (error) {
      _sendError(
        'The API method saveBasket() was failed at the order page.',
        error
      );

      if (error.response && error.response.status === 403) {
        dispatch(
          errorHandling({
            has403: true,
          })
        );
      }

      return Promise.reject(error);
    }
  };

export const getTotalBasketPrice =
  (currencyISO: string) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const queryParams = _getQueryParams(state);
    const auth = _getAuth(state);

    try {
      return await Api.getTotalBasketPrice(currencyISO, queryParams, auth);
    } catch (error) {
      _sendError('The API method getTotalBasketPrice() was failed.', error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }

      return Promise.reject(error);
    }
  };

export const createOrder =
  (stripeToken: string) => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const queryParams = _getQueryParams(state);
      const auth = _getAuth(state);

      return await Api.createOrder(stripeToken, queryParams, auth);
    } catch (error) {
      _sendError('The API method createOrder() was failed.', error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }

      return Promise.reject(error);
    }
  };

export const addPurchaseStatisticsGTM =
  (order: Order, paymentSystem: string, iso: Currencies = Currencies.USD) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const basket = _getObjectsBasket(state);
    const currencyUSD = { iso };
    const prices = _getPrices(state, currencyUSD);
    const products = basket.map<PurchaseStatisticsProduct>(product => {
      const { selected } = prices.find(
        price => price.bundle === product.bundle
      );

      return {
        ...product,
        price: selected.price,
      };
    });

    if (isCN)
      AdobeAnalytics.addPurchaseStatistics(
        products,
        order,
        currencyUSD.iso,
        paymentSystem
      );
    else
      GoogleAnalytics4.addPurchaseStatistics(products, order, currencyUSD.iso);

    AnalyticsManager.googleLegacy.addPurchaseStatistics(
      products,
      order,
      currencyUSD.iso
    );
  };
