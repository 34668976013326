import { AuthActionType } from '@redux/reducers/auth/enum';
import { QueryState } from '@redux/reducers/queryParams/types';
import { clearProfile, getUserProfile } from '@redux/reducers/profile/action';
import { setCountry } from '@redux/reducers/country/action';

import AnalyticsManager from '@utils/analyticsManager';

import { addUserDetailsCountly } from '@utils/analytics';
import { clientCookies } from '@utils/clientCookies';

import Cookies from 'universal-cookie';
import { clearBasket } from '../basket/action';
import { errorHandling, loadApplications } from '../applications/action';

export const setSessionId = (sessionId: string) => ({
  type: AuthActionType.SetSessionId,
  payload: sessionId,
});

export const setBearer = (bearer: string) => ({
  type: AuthActionType.SetBearer,
  payload: bearer,
});

export const clearAuth = () => ({
  type: AuthActionType.ClearAuth,
});

export const logout =
  (cookies: Cookies = clientCookies) =>
  dispatch => {
    AnalyticsManager.shared.subHeader.addLogout();
    AnalyticsManager.googleLegacy.subHeader.addLogout();

    dispatch(clearAuth());
    dispatch(clearProfile());
    cookies.remove('access_token', { path: '/' });
    cookies.remove('sessionid', { path: '/' });
    dispatch(clearBasket());

    try {
      dispatch(loadApplications());
    } catch (error) {
      dispatch(errorHandling(error));
    }
  };

export const setInitialSession =
  (query: QueryState, cookies: Cookies) => dispatch => {
    const bearer = cookies.get('access_token');
    let sessionId = cookies.get('sessionid');

    if (query.sessionid) {
      cookies.set('sessionid', query.sessionid, {
        path: '/',
        secure: true,
      });
      sessionId = query.sessionid;
    }

    if (bearer) {
      dispatch(setBearer(bearer));
    } else if (sessionId) {
      dispatch(setSessionId(sessionId));
    }
  };

export const login =
  (getApplications = true) =>
  async dispatch => {
    AnalyticsManager.shared.subHeader.addLogin();
    AnalyticsManager.googleLegacy.subHeader.addLogin();

    let userProfile;

    try {
      userProfile = await dispatch(getUserProfile());
    } catch (error) {
      dispatch(errorHandling(error));

      return Promise.reject(error);
    }

    if (!userProfile) {
      return Promise.reject();
    }

    addUserDetailsCountly(userProfile);

    if (getApplications) {
      try {
        await Promise.all([
          dispatch(
            setCountry(userProfile.updatedCountry ?? userProfile.country)
          ),
          dispatch(loadApplications()),
        ]);
      } catch (error) {
        dispatch(errorHandling(error));

        return Promise.reject();
      }
    }
  };
