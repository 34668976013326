import { ProfileCountry } from '@redux/reducers/profile/types';

import { Currencies } from '@shared/enums/currency';

export const PROFILE_KINDS = [
  {
    key: 'academic',
    value: 1,
  },
  {
    key: 'healthcare-provider',
    value: 2,
  },
  {
    key: 'student',
    value: 3,
  },
  {
    key: 'alternative-practitioner',
    value: 4,
  },
  {
    key: 'enthusiast',
    value: 5,
  },
];

export const DEFAULT_COUNTRY: ProfileCountry = {
  id: 233,
  flags: {
    28: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/28/us.jpg',
    56: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/56/us.jpg',
    70: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/70/us.jpg',
    84: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/84/us.jpg',
    140: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/140/us.jpg',
    210: 'https://3d4medical-accounts.s3.amazonaws.com/upload/country_flag/210/us.jpg',
  },
  currency: {
    id: 1,
    iso: Currencies.USD,
    symbol: '$',
    rate: 1.0,
    paypal: true,
    stripe: true,
    zero_decimal: false,
    max_total: 52999,
    alipay: false,
    default: false,
  },
  name: 'United States(USA)',
  iso: 'US',
  tax_rate: 0.0,
};

export const DELETION_STATUS = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  COMPLETED: 'Completed',
};
