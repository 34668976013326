// process.env is not available in Сypress
// was added for Cypress component testing

import { checkForExcludedLink } from '@utils/checkNonProdPrefix';

import { env } from 'next-runtime-env';

const rowBase = '3d4medical.com';
const cnBase = 'completeanatomy.cn';

export const ORIGIN = !(env('NEXT_PUBLIC_BASE_URI') || '').includes(rowBase)
  ? cnBase
  : rowBase;

export const isCN = ORIGIN === cnBase;

export const isNonProd = env('NEXT_PUBLIC_BASE_URI')
  ? env('NEXT_PUBLIC_BASE_URI').includes('nonprod')
  : true;

export const ENVDOMAIN = isNonProd ? `nonprod.${ORIGIN}` : ORIGIN;

export const envUrl = (
  url: string,
  withoutNonProdPrefix = false,
  key?: string
) => {
  if (key) withoutNonProdPrefix = checkForExcludedLink(key);
  if (!isNonProd || withoutNonProdPrefix) return url;

  const corpSitePrefix = 'corpsite.';

  let newUrl = url;
  newUrl = newUrl.replace(rowBase, `${corpSitePrefix}nonprod.${rowBase}`);
  newUrl = newUrl.replace(cnBase, `${corpSitePrefix}nonprod.${cnBase}`);

  return newUrl;
};

export const cnUrl = (url: string) =>
  isCN ? url.replace(rowBase, cnBase) : url;

export const getUrl = (url: string, key?: string) => {
  let newUrl = url;

  const withoutNonProdPrefix = checkForExcludedLink(key);

  newUrl = envUrl(newUrl, withoutNonProdPrefix);
  newUrl = cnUrl(newUrl);

  return newUrl;
};
