import React, { Component, createRef } from 'react';

import { connect } from 'react-redux';

import { AppState } from '@redux/types';
import { detectCountry } from '@redux/reducers/country/action';
import {
  hideRegionSelectPopup,
  showRegionSelectPopup,
} from '@redux/reducers/popups/action';
import { loadApplications } from '@redux/reducers/applications/action';
import { saveCurrency } from '@redux/reducers/currency/action';

import AdobeAnalytics from '@utils/adobeAnalytics';
import { ORIGIN, isNonProd } from '@utils/origin';

import { Currencies } from '@shared/enums/currency';
import {
  Providers,
  RegionSelectPopup,
  RegionSelectPopupRef,
} from '@3d4medical/regions-popup';
import { i18n } from 'i18n';

export type RegionSelectPopupComponentProps = {
  show?: (force?: { force: boolean }) => void;
  hide?: () => void;
  open?: () => void;
  close?: () => void;
  loadApplications?: (lang: string) => void;
  detectCountry?: (reg: string) => void;
  saveCurrency?: (iso: Currencies) => void;
  isOpen: boolean;
  force: boolean;
};

class RegionSelectPopupComponent extends Component<RegionSelectPopupComponentProps> {
  popup: React.RefObject<RegionSelectPopupRef> = createRef();

  origin = isNonProd ? `nonprod.${ORIGIN}` : ORIGIN;

  componentDidMount() {
    this.props.show();
  }

  componentWillUnmount() {
    this.popup.current.close();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen &&
      this.props.isOpen !== prevProps.isOpen &&
      (this.props.force || this.popup.current.shouldShow())
    ) {
      this.popup.current.open();

      return;
    }

    if (this.props.isOpen === false && this.props.isOpen !== prevProps.isOpen) {
      this.popup.current.close();

      return;
    }

    this.props.hide();
  }

  composeURL(origin, queryParams) {
    const search = new URLSearchParams(encodeURI(window.location.search));

    Object.keys(queryParams).forEach(key => {
      search.set(key, queryParams[key]);
    });

    return `https://${origin}${window.location.pathname}?${search.toString()}`;
  }

  onClose = options => {
    const queryParams = {
      reg: options.region,
      cur: options.currency,
      lang: options.language,
    };

    if (
      options.region !== options.detectedRegion ||
      options.language !== options.detectedLanguage
    ) {
      AdobeAnalytics.selectRegion.switchRegion(options);
    }

    switch (options.region) {
      case 'CN':
        if (ORIGIN !== 'completeanatomy.cn') {
          window.location.href = this.composeURL(
            window.location.host.replace(
              '3d4medical.com',
              'completeanatomy.cn'
            ),
            queryParams
          );
        }
        break;
      default:
        if (ORIGIN !== '3d4medical.com') {
          window.location.href = this.composeURL(
            window.location.host.replace(
              'completeanatomy.cn',
              '3d4medical.com'
            ),
            queryParams
          );
        }
    }

    i18n.changeLanguage(options.language);

    this.props.loadApplications(options.language);
    this.props.detectCountry(options.region);
    this.props.saveCurrency(options.currency);

    this.props.hide();
  };

  render() {
    return (
      <Providers>
        <RegionSelectPopup
          onClose={this.onClose}
          origin={this.origin}
          ref={this.popup}
        />
      </Providers>
    );
  }
}

export default connect(
  (state: AppState) => ({
    isOpen: state.popups.regionSelect?.isOpen,
    force: state.popups.regionSelect?.force || false,
  }),
  (dispatch: any) => ({
    show: (force: { force: boolean }) => dispatch(showRegionSelectPopup(force)),
    hide: () => dispatch(hideRegionSelectPopup()),
    loadApplications: (lang: string) => dispatch(loadApplications(lang)),
    detectCountry: (reg: string) => dispatch(detectCountry(reg)),
    saveCurrency: (iso: Currencies) => dispatch(saveCurrency(iso)),
  })
)(RegionSelectPopupComponent);
