import { AppState } from '@redux/types';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';

import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from 'redux';
import * as reducers from 'redux/reducers';

import thunkMiddleware from 'redux-thunk';

import isServer from '@utils/isServer';

import { env } from 'next-runtime-env';

const combinedReducer = combineReducers({
  ...reducers,
});

const reducer = (state: AppState, action) => {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  } else {
    return combinedReducer(state, action);
  }
};

const bindMiddleware = middleware => {
  if (env('NEXT_PUBLIC_ENVIRONMENT') !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

let middleware = [thunkMiddleware];
if (!isServer() && env('NEXT_PUBLIC_ENVIRONMENT') !== 'production') {
  middleware = [...middleware, require('redux-logger').createLogger()];
}

const initStore = () => createStore(reducer, bindMiddleware(middleware));

export const wrapper = createWrapper(initStore);
