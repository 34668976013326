import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import { AppDispatch } from 'redux-types';
import { AppState } from '@redux/types';
import { _getProfile } from '@redux/reducers/profile/selectors';
import { compose } from 'redux';
import { detectCountry } from '@redux/reducers/country/action';
import { wrapper } from '@redux/store';

import PageTitle from '@components/atoms/PageTitle/PageTitle';
import RegionSelectPopup from '@components/organisms/RegionSelectPopup';

import GlobalStyles from '@components/GlobalStyles';

import AnalyticsManager from '@utils/analyticsManager';
import isServer from '@utils/isServer';
import { loadAnalytics } from '@utils/analytics';

import App from 'next/app';
import Countly from 'countly-sdk-web';
import Head from 'next/head';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { NextComponentType } from 'next';
import Router, { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { _isIEBrowser, _isServicePage } from '@shared/router/function';
import { appWithTranslation, withTranslation } from 'i18n';
import { theme } from 'theme';

const handleRouteChange = url => {
  AnalyticsManager.adobeAnalytics.addPageView(url);
  AnalyticsManager.googleLegacy.addPageView();

  // Countly Analytics
  if (!isServer()) {
    Countly.q.push(['track_pageview', url]);
  }
};

type MyAppProps = WithRouterProps & {
  Component: NextComponentType;
  pageProps: any;
  dispatch: AppDispatch;
  t: I18nTranslation;
};

class MyApp extends App<MyAppProps> {
  componentDidMount() {
    const { router, dispatch } = this.props;

    if (_isIEBrowser() || _isServicePage(router.pathname)) {
      return;
    }

    const reg = router.query.reg as string;

    dispatch(detectCountry(reg));

    loadAnalytics();

    Router.events.on('routeChangeComplete', handleRouteChange);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', handleRouteChange);
  }

  render() {
    const { Component, pageProps, t } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>
          <PageTitle title={t('title')} />
          <GlobalStyles />
          <RegionSelectPopup />
          <Component {...pageProps} />
        </Fragment>
      </ThemeProvider>
    );
  }
}

// the 'dispatch' function will be in the props
const withConnect = connect((state: AppState) => {
  const profile = _getProfile(state);
  AnalyticsManager.setUserProfile(profile);

  return {};
});

export default compose(
  withRouter,
  wrapper.withRedux,
  withConnect,
  appWithTranslation,
  withTranslation('common')
)(MyApp);
