import { BillingHistory, PlatformBuy } from '@redux/reducers/profile/types';

import { STORE_SOURCE } from '@shared/markup/constants';
import { SubscriptionBuy } from '@shared/interfaces/subscription-buy';
import { UserStore } from '@shared/interfaces/user-store';

export type FormattedPurchasedInfo = {
  platformBuys: PlatformBuy[];
  subscriptionBuys: SubscriptionBuy[];
  billingHistory: BillingHistory[];
  currencies: string[];
  last4: string;
  brand: string;
};

export function getFormattedPurchasedInfo({
  platformBuys: platforms,
  subscriptionBuys: subscriptions,
  billingHistory: billingHistory,
  currencies,
  last4,
  brand,
}: UserStore): FormattedPurchasedInfo {
  const subscriptionBuys = subscriptions.reduce((acc, subscription) => {
    const {
      application: appId,
      storeLink: storeLink,
      createdTimestamp: createdTimestamp,
      storeName: storeName,
      offerAccepted: offerAccepted,
      offerApplicable: offerApplicable,
      renewalAmount: renewalAmount,
      renewalCurrency: renewalCurrency,
      ...rest
    } = subscription;

    const formattedSubscription = {
      offerAccepted: offerAccepted,
      offerApplicable,
      renewalAmount,
      ...rest,
      storeLink,
      appId,
      createdTimestamp,
      storeName,
      renewalCurrency,
    };

    if (
      formattedSubscription.store === STORE_SOURCE.OUR ||
      formattedSubscription.store === STORE_SOURCE.OFFICIAL
    ) {
      return [...acc, { ...formattedSubscription }];
    }

    return [...acc];
  }, []);

  const platformBuys = platforms.map(platform => {
    return {
      name: platform.name,
      icon: platform.platform_icon,
      createdTimestamp: platform.created_timestamp,
      appId: platform.application,
    };
  });

  return {
    platformBuys,
    subscriptionBuys,
    billingHistory,
    currencies,
    last4,
    brand,
  };
}
