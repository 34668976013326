import styledBreakpoint from '@humblebee/styled-components-breakpoint';

import {
  Colors,
  FlexboxGrid,
  Theme,
  TransparentColors,
} from '@shared/interfaces/themes';
import { rgba } from 'polished';

export const DESKTOP_PAGE_WIDTH = 61.875;

export enum MEDIA_POINT {
  XS = 320,
  SM = 600,
  LTM = 767,
  M = 768,
  MLM = 809,
  MM = 900,
  MD = 1024,
  MED = 1290,
  MLG = 1340,
  LTLG = 1439,
  LG = 1440,
}

export const breakpoint = styledBreakpoint({
  xs: MEDIA_POINT.XS,
  sm: MEDIA_POINT.SM,
  m: MEDIA_POINT.M,
  ltm: MEDIA_POINT.LTM,
  mm: MEDIA_POINT.MM,
  mlm: MEDIA_POINT.MLM,
  md: MEDIA_POINT.MD,
  med: MEDIA_POINT.MED,
  mlg: MEDIA_POINT.MLG,
  lg: MEDIA_POINT.LG,
  ltLg: MEDIA_POINT.LTLG,
});

const colors: Colors = {
  scarpaFlow: '#58585B',
  alabaster: '#FAFAFA',
  white: '#FFFFFF',
  darkGrey: '#5c656c',
  middleGrey: '#9da0a3',
  alto: '#E0E0E0',
  cerulean: '#00AEEF',
  flushOrange: '#FF8500',
  iron: '#CDCFD0',
  lightIron: '#dfe2e4',
  concrete: '#F3F2F2',
  scarlet: '#FF2C00',
  tundora: '#424242',
  silverTree: '#51b694',
  anzac: '#dcb24c',
  black: '#000',
  fiord: '#424770',
  darkMercury: '#E3E3E3',
  grayChateau: '#9EA3A6',
  nevada: '#666E73',
  silverSand: '#B6BBBE',
  turquoise: '#47CFCB',
  cornflowerBlue: '#17242a',
  edward: '#a3b1b1',
  monument: '#858A8E',
  transparent: 'transparent',
  bombay: '#B4B5B7',
  oxfordBlue: '#334A54',
  osloGray: '#989DA0',
  blackHaze: '#E7E9E9',
  monza: '#d0021b',
  nebula: '#d2dcdb',
  mulberry: '#cf4790',
  offGreen: '#e1f7f6',
  midnightBlue: '#001E6D',
  sunglow: '#FFC439',
  saffron: '#F2BA38',
  karry: '#FFEAD4',
  keppel: '#37A5A2',
  outerSpace: '#2d3a3f',
  robinEggBlue: '#00BAB5',
  blazeOrange: '#FF6C00',
  coral: '#FF984D',
  puertoRico: '#4DBC96',
  olsoGray: '#8b9194',
  wildSand: '#F4F4F4',
};

const transparentColors: TransparentColors = {
  turquoise10: rgba(colors.turquoise, 0.1),
  turquoise50: rgba(colors.turquoise, 0.5),
  nevada20: rgba(colors.nevada, 0.2),
  lightIron50: rgba(colors.lightIron, 0.5),
  black15: rgba(colors.black, 0.15),
  black25: rgba(colors.black, 0.25),
  black50: rgba(colors.black, 0.5),
  black75: rgba(colors.black, 0.75),
  flushOrange20: rgba(colors.flushOrange, 0.2),
  flushOrange40: rgba(colors.flushOrange, 0.4),
  white60: rgba(colors.white, 0.6),
  cornflowerBlue30: rgba(colors.cornflowerBlue, 0.3),
  puertoRico50: rgba(colors.puertoRico, 0.5),
};

const flexboxgrid: FlexboxGrid = {
  // Defaults
  gridSize: 12, // columns
  gutterWidth: 1, // rem
  outerMargin: 2, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: MEDIA_POINT.M / 16, // === em
    md: MEDIA_POINT.MD / 16, // === em
    lg: MEDIA_POINT.LG / 16, // === em
  },
};

export const theme: Theme = {
  colors,
  transparentColors,
  gutter: 0.5,
  flexboxgrid,
};

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
