import { AuthState, HeaderAuth } from '@redux/reducers/auth/types';
import { QueryState } from '@redux/reducers/queryParams/types';
import { SaveBasket } from '@redux/reducers/basket/types';

import { clientCookies } from '@utils/clientCookies';

import isServer from '@utils/isServer';

import { Account } from '@shared/interfaces/account';
import { Bundle } from '@shared/enums/bundle';
import { Cart } from '@shared/interfaces/cart';
import { Chose } from '@shared/interfaces/chose';
import { Country, CountryV2 } from '@shared/interfaces/country';
import { Currency } from '@shared/interfaces/currency';
import { Feedback } from '@shared/interfaces/feedback';
import { Offer, OfferResponse } from '@shared/interfaces/offer';
import { Order } from '@shared/interfaces/order';
import { PaymentType } from '@shared/enums/PaymentType';
import { Product } from '@shared/interfaces/product';
import {
  SubscriptionBuy,
  SubscriptionBuyResponse,
} from '@shared/interfaces/subscription-buy';
import { UserStore, UserStoreResponse } from '@shared/interfaces/user-store';
import axios, { AxiosResponse } from 'axios';
import { env } from 'next-runtime-env';

const Accounts = !isServer() ? require('./accounts').default : null;

const BASE_URI = env('NEXT_PUBLIC_BASE_URI');
const OAUTH_SERVER = env('NEXT_PUBLIC_OAUTH_SERVER');
const OAUTH_REDIRECT_URI = env('NEXT_PUBLIC_OAUTH_REDIRECT_URI');
const OAUTH_CLIENT_ID = env('NEXT_PUBLIC_OAUTH_CLIENT_ID');
const OAUTH_BLANK = env('NEXT_PUBLIC_OAUTH_BLANK');

const axiosInstance = axios.create({
  baseURL: `${BASE_URI}/api/`,
});

class Api {
  getHeaderAuth = ({ bearer, sessionId }: AuthState): HeaderAuth => {
    let auth = {};

    if (sessionId) {
      auth = {
        sessionid: sessionId,
      };
    }
    if (bearer) {
      auth = {
        Authorization: `Bearer ${bearer}`,
      };
    }

    return auth;
  };

  getHeaderPromo = ({ promo }: QueryState): { Promo: string } | {} => {
    return promo ? { Promo: promo } : {};
  };

  getHeaderContentLanguage = (
    language: string
  ): { 'accept-language'?: string } | {} => {
    const _language = language || clientCookies.get('wp-wpml_current_language');

    return _language ? { 'accept-language': _language } : {};
  };

  createAccount(
    queryParams: QueryState
  ): Promise<{ profile: Account; bearer: string }> {
    const promo = queryParams.promo;

    return Accounts.createAccount(
      OAUTH_SERVER,
      OAUTH_REDIRECT_URI + '?redirect_state=x',
      OAUTH_CLIENT_ID,
      {
        promo,
      }
    )
      .then(data => {
        if (data && data.closed) {
          const bearer = clientCookies.get('access_token');
          const sessionId = clientCookies.get('sessionid');
          if (bearer || sessionId) {
            return this.getUserDetailInfo(bearer, sessionId);
          } else {
            throw new Error(`token doesn't exist`);
          }
        }
      })
      .catch(err => {
        if (err.message === `token doesn't exist`) {
          return 'Popup closed.';
        } else {
          throw err;
        }
      });
  }

  confirmCredentials(onLogin, prev) {
    onLogin(false);

    let bearer = clientCookies.get('access_token');
    let sessionId = clientCookies.get('sessionid');

    clientCookies.remove('access_token');
    clientCookies.remove('sessionid');

    return Accounts.confirmCredentials(
      OAUTH_SERVER,
      OAUTH_REDIRECT_URI + '?redirect_state=x',
      OAUTH_CLIENT_ID
    )
      .then(data => {
        if (data && data.closed) {
          bearer = clientCookies.get('access_token');
          sessionId = clientCookies.get('sessionid');
          if (bearer || sessionId) {
            onLogin(true, bearer, sessionId);
          } else {
            console.log(`token doesn't exist`);
            prev();
          }
        }
      })
      .catch(err => {
        if (err.message === 'Popup closed') {
          console.warn('SSO popup closed');

          return 'closed';
        } else {
          throw err;
        }
      });
  }

  changeImage(auth: AuthState) {
    return Accounts.changeImage(OAUTH_SERVER, OAUTH_BLANK + '?redirect_state=x')
      .then(data => {
        if (data && data.closed) {
          const bearer = auth.bearer;
          const sessionId = auth.sessionId;
          if (bearer || sessionId) {
            return this.getUserDetailInfo(bearer, sessionId);
          } else {
            console.log(`token doesn't exist`);
          }
        }
      })
      .catch(err => {
        if (err.message === 'Popup closed') {
          console.warn('SSO popup closed');

          return 'closed';
        } else {
          throw err;
        }
      });
  }

  changePassword(auth: AuthState) {
    return Accounts.changePassword(
      OAUTH_SERVER,
      OAUTH_REDIRECT_URI + '?redirect_state=x',
      OAUTH_CLIENT_ID
    )
      .then(data => {
        if (data && data.closed) {
          const bearer = auth.bearer;
          const sessionId = auth.sessionId;
          if (bearer || sessionId) {
            return this.getUserDetailInfo(bearer, sessionId);
          } else {
            console.log(`token doesn't exist`);
          }
        }
      })
      .catch(err => {
        if (err.message === 'Popup closed') {
          console.warn('SSO popup closed');

          return 'closed';
        } else {
          throw err;
        }
      });
  }

  changeInfo(auth: AuthState): Promise<{ profile: Account; bearer: string }> {
    return Accounts.changeInfo(OAUTH_SERVER, OAUTH_BLANK + '?redirect_state=x')
      .then(data => {
        if (data && data.closed) {
          const bearer = auth.bearer;
          const sessionId = auth.sessionId;
          if (bearer || sessionId) {
            return this.getUserDetailInfo(bearer, sessionId);
          } else {
            console.log(`token doesn't exist`);
          }
        }
      })
      .catch(err => {
        if (err.message === 'Popup closed') {
          console.warn('SSO popup closed');

          return 'closed';
        } else {
          throw err;
        }
      });
  }

  changeEmailPreferences(
    auth: AuthState
  ): Promise<{ profile: Account; bearer: string }> {
    return Accounts.changeEmailPreferences(
      OAUTH_SERVER,
      OAUTH_BLANK + '?redirect_state=x'
    )
      .then(data => {
        if (data && data.closed) {
          const bearer = auth.bearer;
          const sessionId = auth.sessionId;
          if (bearer || sessionId) {
            return this.getUserDetailInfo(bearer, sessionId);
          } else {
            console.log(`token doesn't exist`);
          }
        }
      })
      .catch(err => {
        if (err.message === 'Popup closed') {
          console.warn('SSO popup closed');

          return 'closed';
        } else {
          throw err;
        }
      });
  }

  getUserDetailInfo(
    bearer: string,
    sessionId: string
  ): Promise<{ profile: Account; bearer: string }> {
    return this.fetchAccInfo(bearer, sessionId).then(profile => {
      return { profile, bearer };
    });
  }

  fetchAccInfo(bearer: string, sessionId: string): Promise<Account> {
    const headers = this.getHeaderAuth({ bearer, sessionId });
    const config = {
      headers,
    };

    return axiosInstance
      .get<Account>('v1/account/', config)
      .then((response: AxiosResponse<Account>) => response.data);
  }

  getCountries = (): Promise<CountryV2[]> => {
    return axiosInstance
      .get<CountryV2[]>('v2/countries/')
      .then((response: AxiosResponse<CountryV2[]>) => response.data);
  };

  getCurrencies = (): Promise<Currency[]> => {
    return axiosInstance
      .get<Currency[]>('v2/currencies/')
      .then((response: AxiosResponse<Currency[]>) => response.data);
  };

  getCurrency = iso => {
    return axiosInstance
      .get(`v2/currencies/?iso=${iso}`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  setCountry(data: { iso: string }, auth: AuthState): Promise<Chose> {
    const headers = this.getHeaderAuth(auth);
    const config = {
      headers,
    };

    return axiosInstance
      .post<Chose>('v1/countries/choose/', data, config)
      .then((response: AxiosResponse<Chose>) => response.data);
  }

  detectCountry(): Promise<Country> {
    return axiosInstance
      .get<Country>('v1/countries/detect/')
      .then((response: AxiosResponse<Country>) => response.data);
  }

  getApplications = (
    queryParams: QueryState,
    auth: AuthState,
    language?: string
  ): Promise<Product[]> => {
    const promoHeader = this.getHeaderPromo(queryParams);
    const authHeader = this.getHeaderAuth(auth);
    const languageHeader = this.getHeaderContentLanguage(language);

    const headers = {
      ...authHeader,
      ...promoHeader,
      ...languageHeader,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .get<Product[]>('v1/products/', config)
      .then((response: AxiosResponse<Product[]>) => response.data);
  };

  saveBasket = (
    basket: SaveBasket,
    queryParams: QueryState,
    auth: AuthState
  ): Promise<Cart> => {
    const promoHeader = this.getHeaderPromo(queryParams);
    const authHeader = this.getHeaderAuth(auth);
    const headers = {
      ...authHeader,
      ...promoHeader,
    };

    const config = {
      headers,
    };

    return axiosInstance
      .patch<Cart>('v1/carts/', basket, config)
      .then((response: AxiosResponse<Cart>) => response.data);
  };

  getTotalBasketPrice(
    currencyISO: string,
    queryParams: QueryState,
    auth: AuthState
  ) {
    const promoHeader = this.getHeaderPromo(queryParams);
    const authHeader = this.getHeaderAuth(auth);
    const headers = {
      ...authHeader,
      ...promoHeader,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .get(`v1/carts/total/?currency=${currencyISO}`, config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  createOrder(
    stripeToken: string,
    queryParams: QueryState,
    auth: AuthState
  ): Promise<Order> {
    const promoHeader = this.getHeaderPromo(queryParams);
    const token = clientCookies.get('access_token');
    const authHeader = {
      ...this.getHeaderAuth(auth),
      ...this.getHeaderAuth({ bearer: token }),
    };
    const idempotencyKey = crypto.randomUUID();

    const headers = {
      ...authHeader,
      ...promoHeader,
      idempotencyKey,
    };
    const data = stripeToken
      ? {
          token: stripeToken,
        }
      : {};
    const config = {
      headers,
    };

    return axiosInstance
      .post<Order>('v1/carts/purchase/', data, config)
      .then((response: AxiosResponse<Order>) => response.data);
  }

  parseSubscriptionResponse = (
    response: SubscriptionBuyResponse
  ): SubscriptionBuy => {
    const subscription: SubscriptionBuy = {
      application: response.application,
      bundle: response.bundle,
      createdTimestamp: response.created_timestamp,
      expire: response.expire,
      id: response.id,
      image: response.image,
      name: response.name,
      offerAccepted: response.offer_accepted,
      offerApplicable: response.offer_applicable,
      offerDiscount: response.offer_discount,
      paymentSystem: response.payment_system,
      renewalAmount: response.renewal_amount,
      renewalCurrency: response.renewal_currency,
      status: response.status,
      store: response.store,
      storeLink: response.store_link,
      storeName: response.store_name,
      title: response.title,
    };

    return subscription;
  };

  getPurchasedInfo = (
    queryParams: QueryState,
    auth: AuthState
  ): Promise<UserStore> => {
    const promoHeader = this.getHeaderPromo(queryParams);
    const authHeader = this.getHeaderAuth(auth);
    const headers = {
      ...authHeader,
      ...promoHeader,
    };
    const config = {
      headers,
    };

    const getPurchasedData = async () => {
      const response = await axiosInstance.get<UserStoreResponse>(
        'v2/store-users/',
        config
      );
      const purchasedInfoResponse: UserStoreResponse = response.data;
      const subscriptionBuysResponse: SubscriptionBuyResponse[] =
        response.data.subscription_buys;

      const subscriptionBuys: SubscriptionBuy[] = subscriptionBuysResponse.map(
        subscription => {
          return this.parseSubscriptionResponse(subscription);
        }
      );

      const purchasedInfo: UserStore = {
        billingHistory: purchasedInfoResponse.billing_history,
        brand: purchasedInfoResponse.brand,
        currencies: purchasedInfoResponse.currencies,
        email: purchasedInfoResponse.email,
        firstName: purchasedInfoResponse.first_name,
        image: purchasedInfoResponse.image,
        last4: purchasedInfoResponse.last4,
        lastName: purchasedInfoResponse.last_name,
        lastOrder: purchasedInfoResponse.last_order,
        newCustomer: purchasedInfoResponse.new_customer,
        platformBuys: purchasedInfoResponse.platform_buys,
        subscriptionBuys: subscriptionBuys,
      };

      return purchasedInfo;
    };

    return getPurchasedData();
  };

  getLicensesInfo = (auth: AuthState): Promise<SubscriptionBuy[]> => {
    const headers = this.getHeaderAuth(auth);
    const config = {
      headers,
    };

    const getLicensesData = async () => {
      const response = await axiosInstance.get<SubscriptionBuyResponse[]>(
        'v2/user-licenses/',
        config
      );
      const licensesResponse: SubscriptionBuyResponse[] = response.data;

      const licenses: SubscriptionBuy[] = licensesResponse.map(license => {
        return this.parseSubscriptionResponse(license);
      });

      return licenses;
    };

    return getLicensesData();
  };

  getLicenseById = (
    licenseId: number,
    auth: AuthState
  ): Promise<SubscriptionBuy> => {
    const headers = this.getHeaderAuth(auth);
    const config = {
      headers,
    };

    const getLicenseData = async () => {
      const response = await axiosInstance.get<SubscriptionBuyResponse>(
        `v2/user-licenses/${licenseId}/`,
        config
      );
      const license: SubscriptionBuyResponse = response.data;
      const subscription = this.parseSubscriptionResponse(license);

      return subscription;
    };

    return getLicenseData();
  };

  getLicenseNotExpired = async (auth: AuthState): Promise<SubscriptionBuy> => {
    const licenses = await this.getLicensesInfo(auth);
    const isNotExpired = (license: SubscriptionBuy): boolean =>
      new Date(license.expire) > new Date();
    const activeLicense = licenses.find(isNotExpired);

    return activeLicense;
  };

  changeCard(tokens, auth: AuthState): Promise<UserStore> {
    const headers = this.getHeaderAuth(auth);
    const data = {
      tokens,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<UserStore>('v1/store-users/add-card/', data, config)
      .then((response: AxiosResponse<UserStore>) => response.data);
  }

  getPayPal() {
    const token = clientCookies.get('access_token');
    const idempotencyKey = crypto.randomUUID();
    const authHeader = this.getHeaderAuth({ bearer: token });
    const headers = {
      ...authHeader,
      idempotencyKey
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/carts/confirmation/', {}, config)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  }

  sendPayPalData(data) {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });
    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/carts/accept/', data, config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  suspendSubscription = (
    bundle: Bundle,
    auth: AuthState
  ): Promise<UserStore> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<UserStore>('v1/store-users/suspend-subscription/', data, config)
      .then((response: AxiosResponse<UserStore>) => response.data);
  };

  renewSubscription = (bundle: Bundle, auth: AuthState): Promise<UserStore> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<UserStore>('v1/store-users/renew-subscription/', data, config)
      .then((response: AxiosResponse<UserStore>) => response.data);
  };

  getOfferByLicenseId = (
    licenseId: number,
    auth: AuthState
  ): Promise<Offer> => {
    const headers = this.getHeaderAuth(auth);
    const config = {
      headers,
    };

    const getOfferData = async () => {
      const response = await axiosInstance.get<OfferResponse>(
        `v2/user-licenses/${licenseId}/offer/`,
        config
      );
      const offerResponse: OfferResponse = response.data;

      const offer: Offer = {
        amount: offerResponse.amount,
        id: offerResponse.id,
        isAccepted: offerResponse.is_accepted,
      };

      return offer;
    };

    return getOfferData();
  };

  isOfferEligible = async (
    licenseId: number,
    auth: AuthState
  ): Promise<boolean> => {
    const license = await this.getLicenseById(licenseId, auth);
    const offer = await this.getOfferByLicenseId(licenseId, auth);

    const validPayments =
      license.paymentSystem === PaymentType.Stripe ||
      license.paymentSystem === PaymentType.NewPaypal;
    const eligibility = validPayments && offer !== null && !offer.isAccepted;

    return eligibility;
  };

  acceptRenewalOffer = (
    licenseId: number,
    bundle: Bundle,
    auth: AuthState
  ): Promise<SubscriptionBuy> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<SubscriptionBuy>(
        `v2/user-licenses/${licenseId}/accept-offer/`,
        data,
        config
      )
      .then(response => response.data);
  };

  acceptOffer = (bundle: Bundle, auth: AuthState): Promise<UserStore> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/store-users/accept-offer/', data, config)
      .then(response => response.data);
  };

  enableAutomaticRenewal = (
    licenseId: number,
    bundle: Bundle,
    auth: AuthState
  ): Promise<SubscriptionBuy> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<SubscriptionBuy>(
        `v2/user-licenses/${licenseId}/enable-auto-renew/`,
        data,
        config
      )
      .then(response => response.data);
  };

  disableAutomaticRenewal = (
    licenseId: number,
    bundle: Bundle,
    auth: AuthState
  ): Promise<SubscriptionBuy> => {
    const headers = this.getHeaderAuth(auth);
    const data = {
      bundle,
    };
    const config = {
      headers,
    };

    return axiosInstance
      .post<SubscriptionBuy>(
        `v2/user-licenses/${licenseId}/disable-auto-renew/`,
        data,
        config
      )
      .then(response => response.data);
  };

  sendFeedback = (data): Promise<Feedback> => {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });
    const config = {
      headers,
    };

    return axiosInstance
      .post<Feedback>('v2/cancellation-feedback/', data, config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  alipayAgreement = () => {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });

    const config = {
      headers,
    };

    return axiosInstance
      .get('v1/carts/alipay-agreement/', config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  alipayConfirm = data => {
    const token = clientCookies.get('access_token');
    const authHeader = this.getHeaderAuth({ bearer: token });
    const idempotencyKey = crypto.randomUUID();
    const headers = {
      authHeader,
      idempotencyKey,
    }
    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/carts/alipay-confirm/', data, config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  acceptAlipay = data => {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });

    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/carts/accept-alipay/', data, config)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  switchLicense = () => {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });

    const config = {
      headers,
    };

    return axiosInstance
      .post('v1/store-users/swap-license/', {}, config)
      .then(response => response.status)
      .catch(error => error.response.status)
      .catch(() => -1);
  };

  accountDeletion = (stop = false) => {
    const token = clientCookies.get('access_token');
    const headers = this.getHeaderAuth({ bearer: token });

    const config = {
      headers,
    };

    const path = stop ? 'stop' : 'start';

    return axiosInstance
      .post(`v1/account/${path}-deletion/`, {}, config)
      .then(response => response.status)
      .catch(error => error.response.status)
      .catch(() => -1);
  };
}

export default new Api();
