import { createGlobalStyle } from 'styled-components';

import fonts from '../public/fonts/fonts';

import { theme } from 'theme';

const GlobalStyles = createGlobalStyle`
  ${fonts}

  html {
    font-family: Main, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
    line-height: 1;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    background-color: ${theme.colors.white};
  }

  html {
    font-family: Main, sans-serif;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: Main, sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  html button,
  html input,
  html optgroup,
  html select,
  html textarea {
    font-family: Main, sans-serif;
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    min-width: 320px;
    -webkit-overflow-scrolling: touch;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .main, #__next {
    height: 100%;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

export default GlobalStyles;
