import { AppState } from '@redux/types';
import { AuthState } from '@redux/reducers/auth/types';

export function _isAuthorized(state: AppState): boolean {
  const auth = _getAuth(state);

  return !!(auth.bearer || auth.sessionId);
}

export function _getAuth(state: AppState): AuthState {
  return state.auth;
}
