import { AppState } from '@redux/types';
import {
  AutomaticRenewalState,
  ProfileActionType,
} from '@redux/reducers/profile/enum';
import {
  UpdateProfilePayload,
  UpdateProfileWithCountry,
} from '@redux/reducers/profile/types';

import { getFormattedPurchasedInfo } from '@utils/getFormattedData';

import { Account } from '@shared/interfaces/account';
import Api from '@api/index';
import { Feedback } from '@shared/interfaces/feedback';
import { UserStore } from '@shared/interfaces/user-store';
import { _getAuth, _isAuthorized } from '../auth/selectors';
import { _getQueryParams } from '../queryParams/selectors';
import { _sendError } from '@shared/logger/functions';
import { _updateProfileCountry } from './selectors';
import { errorHandling } from '../applications/action';
import { setBearer } from '../auth/action';

export const setProfile = (profile: UpdateProfileWithCountry) => ({
  type: ProfileActionType.Set,
  payload: profile,
});

export const updateProfile = (profile: UpdateProfilePayload) => ({
  type: ProfileActionType.Update,
  payload: profile,
});

export const clearProfile = () => ({
  type: ProfileActionType.Clear,
});

export const getUserProfile =
  () => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const queryParams = _getQueryParams(state);

    try {
      const response = await Api.createAccount(queryParams);
      const { profile, bearer } = {
        profile: response.profile,
        bearer: response.bearer,
      };

      if (profile && bearer) {
        const updatedProfile = _updateProfileCountry(state, profile);

        dispatch(setProfile(updatedProfile));
        dispatch(setBearer(bearer));

        return updatedProfile;
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      _sendError(`The API method createAccount() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        return Promise.reject({ has403: true });
      }
    }
  };

export const getUserDetails =
  () => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      const { profile } = await Api.getUserDetailInfo(
        auth.bearer,
        auth.sessionId
      ).catch(err => {
        console.warn('unsuccessful login!', err);
        throw err;
      });

      const updatedProfile = _updateProfileCountry(state, profile);

      dispatch(setProfile(updatedProfile));
    } catch (error) {
      _sendError(`The API method getUserDetailInfo() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        return Promise.reject({ has403: true });
      }
    }
  };

export const setPurchasedInfo = (purchasedInfo: UserStore) => dispatch => {
  const formattedPurchasedInfo = getFormattedPurchasedInfo(purchasedInfo);

  dispatch(
    updateProfile({
      ...formattedPurchasedInfo,
    })
  );
};

export const getPurchasedInfo =
  () => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const queryParams = _getQueryParams(state);
    const isAuthorized = _isAuthorized(state);
    const auth = _getAuth(state);

    if (isAuthorized) {
      try {
        const purchasedInfo = await Api.getPurchasedInfo(queryParams, auth);
        const { newCustomer } = purchasedInfo;

        dispatch(updateProfile({ isNewCustomer: newCustomer }));
        dispatch(setPurchasedInfo(purchasedInfo));
      } catch (error) {
        return _sendError(
          `The API method getPurchasedInfo() was failed.`,
          error
        );
      }
    }
  };

export const changeUserImage =
  () => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      const { profile } = await Api.changeImage(auth);
      if (profile) {
        dispatch(
          updateProfile({
            image: profile.image,
          })
        );
      }
    } catch (error) {
      _sendError(`The API method changeImage() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }
    }
  };

export const changeUserPassword =
  () => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      await Api.changePassword(auth);
    } catch (error) {
      _sendError(`The API method changePassword() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }
    }
  };

export const changeUserInfo =
  () => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      const { profile } = await Api.changeInfo(auth);
      if (profile) {
        dispatch(
          updateProfile({
            firstName: profile.first_name,
            lastName: profile.last_name,
            kind: profile.kind,
          } as Partial<Account>)
        );
      }
    } catch (error) {
      _sendError(`The API method changeInfo() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }
    }
  };

export const changeEmailPreferences =
  () => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      await Api.changeEmailPreferences(auth);
    } catch (error) {
      _sendError(`The API method changeEmailPreferences() was failed.`, error);

      if (error && error.response && error.response.status === 403) {
        dispatch(errorHandling({ has403: true }));
      }
    }
  };

export const changeCard =
  tokens => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);

      const { brand, last4 } = await Api.changeCard(tokens, auth);
      dispatch(updateProfile({ brand, last4 } as Partial<UserStore>));
    } catch (error) {
      _sendError('The API method changeCard() was failed.', error);

      if (error && error.response && error.response.status === 403) {
        dispatch(
          errorHandling({
            has403: true,
          })
        );
      }

      return Promise.reject(error);
    }
  };

export const acceptOffer =
  bundle => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const auth = _getAuth(state);

    try {
      const purchasedInfo = await Api.acceptOffer(bundle, auth);
      dispatch(setPurchasedInfo(purchasedInfo));
    } catch (error) {
      _sendError('The API method acceptOffer() was failed.', error);
    }
  };

export const acceptRenewalOffer =
  (bundle: string) => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);
      const queryParams = _getQueryParams(state);

      const licenses = await Api.getLicensesInfo(auth);
      const license = licenses.find(
        l => (l.bundle).toLowerCase() === bundle.toLowerCase());

      const eligibleForOffer = await Api.isOfferEligible(
        license.id,
        auth
      );

      if (eligibleForOffer) {
        await Api.acceptRenewalOffer(license.id, license.bundle, auth);

        const purchasedInfo = await Api.getPurchasedInfo(queryParams, auth);
        dispatch(setPurchasedInfo(purchasedInfo));
      }
    } catch (error) {
      _sendError('The API method acceptRenewalOffer() had failed.', error);
    }
  };

export const toggleAutomaticRenewal =
  (bundle: string) => async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);
      const queryParams = _getQueryParams(state);

      const licenses = await Api.getLicensesInfo(auth);
      const license = licenses.find(
        l => (l.bundle).toLowerCase() === bundle.toLowerCase());

      if (license.status === AutomaticRenewalState.Enabled || 
        license.status === AutomaticRenewalState.BillingRetry) {
        await Api.disableAutomaticRenewal(license.id, license.bundle, auth);
      } else if (license.status === AutomaticRenewalState.Disabled) {
        await Api.enableAutomaticRenewal(license.id, license.bundle, auth);
      }

      const purchasedInfo = await Api.getPurchasedInfo(queryParams, auth);
      dispatch(setPurchasedInfo(purchasedInfo));
    } catch (error) {
      _sendError('The API method changeAutomaticRenewal() had failed.', error);
    }
  };

export const createFeedback =
  (reason: string, bundle: string, feedbackDetails?: string) =>
  async (dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      const auth = _getAuth(state);
      const licenses = await Api.getLicensesInfo(auth);
      const license = licenses.find(
        l => (l.bundle).toLowerCase() === bundle.toLowerCase());
      let details = '';

      if (feedbackDetails) {
        if (feedbackDetails.length <= 2500) {
          details = feedbackDetails;
        } else {
          const error = 'Feedback input surpasses max length of 2500.';
          throw error;
        }
      }

      const newFeedback: Feedback = {
        buy: license.id,
        reason: reason,
        details: details,
      };

      await Api.sendFeedback(newFeedback);
    } catch (error) {
      if (typeof error === 'string') {
        _sendError(error);
      } else {
        _sendError('The API method createFeedback() had failed.', error);
      }
    }
  };
