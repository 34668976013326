import { UpdateProfileWithCountry } from '@redux/reducers/profile/types';

import { ENVDOMAIN, isCN, isNonProd } from '@utils/origin';
import { deleteCookies, listenCookieChange } from '@utils/cookiesManager';

import AdobeAnalytics from './adobeAnalytics';
import Countly from 'countly-sdk-web';

import { env } from 'next-runtime-env';

export const loadAnalytics = () => {
  listenCookieChange('showAnalyticsCookies', function (value) {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }

    if (value === true) {
      if (isCN) {
        loadAdobeAnalytics();
      } else {
        loadGoogleTagManager();
      }
      loadCountly();
    } else if (value === false) {
      deleteCookies();
    }
  });
};

export const loadAdobeAnalytics = () => {
  AdobeAnalytics.trackPageLoad();
};

export const loadGoogleTagManager = () => {
  let gtm = 'GTM-NJH99VL';
  let qa = '';
  if (env('NEXT_PUBLIC_ENVIRONMENT') === 'production') {
    gtm = 'GTM-PLCCR5F';
    qa = '&gtm_auth=GdZujn9wieEqR6HISRLTHQ&gtm_preview=env-2&gtm_cookies_win=x';
  }

  (function (a, s, y, n, c, h, i, d, e) {
    s.className += ' ' + y;
    // @ts-ignore
    h['start'] = 1 * new Date();
    h['end'] = i = function () {
      s.className = s.className.replace(RegExp(' ?' + y), '');
    };
    (a[n] = a[n] || []).hide = h;
    setTimeout(function () {
      i();
      h['end'] = null;
    }, c);
    h['timeout'] = c;
  })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, {
    'GTM-PLCCR5F': true,
  });
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    let f = d.getElementsByTagName(s)[0];
    let j = d.createElement(s);
    let dl = l !== 'dataLayer' ? '&l=' + l : '';
    j['async'] = true;
    j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + qa;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtm);
};

export const loadCountly = () => {
  const productionAppId = isCN
    ? '9345611041e244e1f92db236da5f4433b937374c'
    : '6e70d0214377f8ab9d3c15e785423968e652c718';
  const developAppId = isCN
    ? 'e2887639fc4cdc0a76056e309749f5f8ff25a7ba'
    : 'dedf7a3a9092b5d86dea655fe78c3a73cb0d1384';

  Countly.app_key =
    env('NEXT_PUBLIC_ENVIRONMENT') === 'production'
      ? productionAppId
      : developAppId;

  const url = isCN
    ? `https://countly.${ENVDOMAIN}`
    : 'https://3d4medical.count.ly';

  Countly.url = url;

  Countly.q.push(['track_sessions']);

  if (Countly.init) {
    Countly.init();
  }
};

const isLoadedCountly = () => {
  return Countly && Countly.q;
};

export const addUserDetailsCountly = (user: UpdateProfileWithCountry) => {
  if (isLoadedCountly()) {
    Countly.q.push([
      'user_details',
      {
        name: user.first_name,
        email: user.email,
        picture: user.image,
      },
    ]);
    Countly.q.push(['change_id', `sso-${user.id}`, true]);
  }
};
