import { css } from 'styled-components';

import NotoSansSCBoldWoff from './zh/subsets/NotoSansSCBold-subset.woff';
import NotoSansSCBoldWoff2 from './zh/subsets/NotoSansSCBold-subset.woff2';
import NotoSansSCRegularWoff from './zh/subsets/NotoSansSCRegular-subset.woff';
import NotoSansSCRegularWoff2 from './zh/subsets/NotoSansSCRegular-subset.woff2';
import SourceSansProBold from './en/SourceSansProBold.ttf';
import SourceSansProExtraLight from './en/SourceSansProExtraLight.ttf';
import SourceSansProItalic from './en/SourceSansProItalic.ttf';
import SourceSansProLight from './en/SourceSansProLight.ttf';
import SourceSansProRegular from './en/SourceSansProRegular.ttf';
import SourceSansProSemiBold from './en/SourceSansProSemiBold.ttf';

export default css`
  /* Ellipsis */
  @font-face {
    font-family: 'Ellipsis';
    src: local('Arial');
    unicode-range: U+2026;
  }

  /* en */
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 200;
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
      url(${SourceSansProExtraLight}) format('truetype');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 300;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
      url(${SourceSansProLight}) format('truetype');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro'), local('SourceSansPro-Regular'),
      url(${SourceSansProRegular}) format('truetype');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
      url(${SourceSansProSemiBold}) format('truetype');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
      url(${SourceSansProBold}) format('truetype');
  }
  @font-face {
    font-family: Main;
    font-style: italic;
    font-weight: 400;
    src: local('Source Sans Pro Italic'), local('SourceSansPro-It'),
      url(${SourceSansProItalic}) format('truetype');
  }

  /* zh */
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+4E00-9FFF, U+2B740-2B81F;
    src: url(${NotoSansSCRegularWoff2}) format('woff2'),
      url(${NotoSansSCRegularWoff}) format('woff');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+4E00-9FFF, U+2B740-2B81F;
    src: url(${NotoSansSCRegularWoff2}) format('woff2'),
      url(${NotoSansSCRegularWoff}) format('woff');
  }
  @font-face {
    font-family: Main;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+4E00-9FFF, U+2B740-2B81F;
    src: url(${NotoSansSCRegularWoff2}) format('woff2'),
      url(${NotoSansSCRegularWoff}) format('woff');
  }
  @font-face {
    font-family: Main;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+4E00-9FFF, U+2B740-2B81F;
    src: url(${NotoSansSCBoldWoff2}) format('woff2'),
      url(${NotoSansSCBoldWoff}) format('woff');
  }
`;
